import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import {
  BrowserClient,
  breadcrumbsIntegration,
  browserTracingIntegration,
  dedupeIntegration,
  defaultStackParser,
  getCurrentHub,
  globalHandlersIntegration,
  linkedErrorsIntegration,
  makeFetchTransport,
} from "@sentry/react";
import { DateTime, Settings } from "luxon";
import type { Zone } from "luxon";

const _RegisterComponent = lazy(() => import("./components/register"));
const RegisterComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_RegisterComponent {...props} />
  </Suspense>
);
const _LayoutComponent = lazy(() => import("./components/layout"));
const LayoutComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_LayoutComponent {...props} />
  </Suspense>
);
const _DashboardComponent = lazy(() => import("./components/dashboard"));
const DashboardComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_DashboardComponent {...props} />
  </Suspense>
);
const _TeamsComponent = lazy(() => import("./components/teams"));
const TeamsComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_TeamsComponent {...props} />
  </Suspense>
);
const _TeamDetailsComponent = lazy(() => import("./components/team-details"));
const TeamDetailsComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_TeamDetailsComponent {...props} />
  </Suspense>
);
const _ActionItemsComponent = lazy(() => import("./components/action-items"));
const ActionItemsComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_ActionItemsComponent {...props} />
  </Suspense>
);
const _MeetingsComponent = lazy(() => import("./components/meetings"));
const MeetingsComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_MeetingsComponent {...props} />
  </Suspense>
);
const _MeetingDetailsComponent = lazy(
  () => import("./components/meeting-details")
);
const MeetingDetailsComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_MeetingDetailsComponent {...props} />
  </Suspense>
);
const _NewMeetingComponent = lazy(() => import("./components/new-meeting"));
const NewMeetingComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_NewMeetingComponent {...props} />
  </Suspense>
);
const _MeetingTemplateComponent = lazy(
  () => import("./components/meeting-template")
);
const MeetingTemplateComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_MeetingTemplateComponent {...props} />
  </Suspense>
);
const _MeetingActivityLogComponent = lazy(
  () => import("./components/meeting-activity-log")
);
const MeetingActivityLogComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_MeetingActivityLogComponent {...props} />
  </Suspense>
);
const _AccountSettingsComponent = lazy(
  () => import("./components/account-settings")
);
const AccountSettingsComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_AccountSettingsComponent {...props} />
  </Suspense>
);
const _PasswordSettingsComponent = lazy(
  () => import("./components/password-settings")
);
const PasswordSettingsComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_PasswordSettingsComponent {...props} />
  </Suspense>
);
const _GeneralSettingsComponent = lazy(
  () => import("./components/general-settings")
);
const GeneralSettingsComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_GeneralSettingsComponent {...props} />
  </Suspense>
);
const _ApplicationsSettingsComponent = lazy(
  () => import("./components/applications-settings")
);
const ApplicationsSettingsComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_ApplicationsSettingsComponent {...props} />
  </Suspense>
);
const _GuestSettingsComponent = lazy(
  () => import("./components/guest-settings")
);
const GuestSettingsComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_GuestSettingsComponent {...props} />
  </Suspense>
);
const _ManageLicensesComponent = lazy(
  () => import("./components/manage-licenses")
);
const ManageLicensesComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_ManageLicensesComponent {...props} />
  </Suspense>
);
const _PricingLicenseComponent = lazy(
  () => import("./components/pricing-licenses")
);
const PricingLicenseComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_PricingLicenseComponent {...props} />
  </Suspense>
);
const _BillingAddressComponent = lazy(
  () => import("./components/billing-address")
);
const BillingAddressComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_BillingAddressComponent {...props} />
  </Suspense>
);
const _PaymentMethodComponent = lazy(
  () => import("./components/payment-method")
);
const PaymentMethodComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_PaymentMethodComponent {...props} />
  </Suspense>
);
const _CostCalculatorComponent = lazy(
  () => import("./components/cost-calculator")
);
const CostCalculatorComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_CostCalculatorComponent {...props} />
  </Suspense>
);
const _ErrorComponent = lazy(() => import("./components/error"));
const ErrorComponent = (props: any) => (
  <Suspense fallback={<div>Loading...</div>}>
    <_ErrorComponent {...props} />
  </Suspense>
);

const client = new BrowserClient({
  dsn: window.SENTRY_FRONTEND_DSN,
  transport: makeFetchTransport,
  stackParser: defaultStackParser,
  integrations: [
    breadcrumbsIntegration(),
    browserTracingIntegration(),
    dedupeIntegration(),
    globalHandlersIntegration(),
    linkedErrorsIntegration(),
  ],
  tracesSampleRate: 0.1,
});

getCurrentHub().bindClient(client);

if (window.currentUser) {
  getCurrentHub().setUser(window.currentUser);
}

global.React = React;
global.ReactDOM = ReactDOM;

global.Components = {
  RegisterComponent,
  LayoutComponent,
  DashboardComponent,
  TeamsComponent,
  TeamDetailsComponent,
  ActionItemsComponent,
  MeetingsComponent,
  MeetingDetailsComponent,
  NewMeetingComponent,
  MeetingTemplateComponent,
  MeetingActivityLogComponent,
  AccountSettingsComponent,
  PasswordSettingsComponent,
  GeneralSettingsComponent,
  ApplicationsSettingsComponent,
  GuestSettingsComponent,
  ManageLicensesComponent,
  PricingLicenseComponent,
  BillingAddressComponent,
  PaymentMethodComponent,
  CostCalculatorComponent,
  ErrorComponent,
};

if (window.currentUserDateTime) {
  window.browserTimeZoneName = (Settings.defaultZone as Zone).name;
  Settings.defaultZone = DateTime.fromISO(window.currentUserDateTime, {
    setZone: true,
  }).zone;
}
